import { Component, HostListener, Renderer2 } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'insuretecJourney';
  public pageLoader = true
  previousUrl: string;

  constructor(
    private renderer: Renderer2,
    private _router: Router,
  ) {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        let currentUrlSlug = event.url.slice(1);
        if (currentUrlSlug != "insureTec/mta/mta-payment") {
          this.renderer.removeClass(document.body, "blueGradBg");
        }
        if (currentUrlSlug != "insureTec/mta/no-policies") {
          this.renderer.removeClass(document.body, "darkBackground");
        }
        // console.log(currentUrlSlug, "currentUrlSlug");

        this.previousUrl = currentUrlSlug;
      }
    });
  }

  //#LOADER
  // @HostListener('window:load')
  // doSomething() {
  //   this.pageLoader = true;
  //   // this.renderer.addClass(document.body, 'loaded');
  // }
}